import React from 'react';
import cx from 'classnames';
import s from './QuickViewLayout.scss';
import {Cell} from '../Cell/Cell';
import {LayoutComponentProps} from '../../../types/app-types';
import {ProductName} from '../../ProductName/ProductName';
import {ProductOptions} from '../../ProductOptions/ProductOptions';
import {ProductPageButtonsContainer} from '../../ProductPageButtonsContainer/ProductPageButtonsContainer';
import {ProductSku} from '../../ProductSku/ProductSku';
import {ResponsiveGallery} from '../ResponsiveLayout/ResponsiveGallery/ResponsiveGallery';
import {ViewMore} from '../../ViewMore/ViewMore';
import {CloseQuickView} from './CloseQuickView/CloseQuickView';
import {ImageModeValues} from '@wix/wixstores-client-core/dist/es/src/media/constants';
import {ProductPrice} from '../../ProductPrice/ProductPrice';
import {ProductPaymentBreakdown} from '../../ProductPaymentBreakdown/ProductPaymentBreakdown';
import {ProductDiscountName} from '../../ProductDiscountName/ProductDiscountName';

export const QuickViewLayout: React.FunctionComponent<LayoutComponentProps> = ({product, settings, globals}) => {
  const showAutomaticDiscountDataOnProductPage = globals.experiments?.showAutomaticDiscountDataOnProductPage;
  return (
    <div className={s.content}>
      <CloseQuickView />

      <section className={cx(s.col, s.left)}>
        <Cell className={s.gallery}>
          <ResponsiveGallery
            media={product.media}
            imageMode={ImageModeValues.FIT}
            imageRatio={settings.imageRatioId}
            maxRatio={1}
            productName={product.name}
          />
        </Cell>
      </section>
      <section className={cx(s.col, s.right)}>
        <Cell>
          <ProductName name={product.name} />
        </Cell>

        <Cell className={s.price}>
          <ProductPrice />
        </Cell>

        {settings.shouldShowProductDiscountName && showAutomaticDiscountDataOnProductPage && (
          <Cell>
            <ProductDiscountName name={product.itemDiscount.discountRuleName} />
          </Cell>
        )}

        {settings.shouldShowProductPaymentBreakdown && (
          <Cell>
            <ProductPaymentBreakdown />
          </Cell>
        )}

        <Cell className={s.sku}>
          <ProductSku />
        </Cell>

        <Cell className={s.options}>
          <ProductOptions
            shouldShowQuantity={settings.shouldShowQuantity}
            shouldShowStockIndicator={settings.shouldShowStockIndicator}
          />
        </Cell>

        <Cell className={s.actions}>
          <ProductPageButtonsContainer />
        </Cell>

        <Cell className={s.viewMore}>
          <ViewMore />
        </Cell>
      </section>
    </div>
  );
};
